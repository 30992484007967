import React from "react"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

import CarouselSlide from "../carouselSlide"
import Container from "../../reusable/absoluteContainer"

import * as style from "./carousel.module.css"

const carouselData = [
  {
    heading: "Excitement",
    subHeading: "from users",
    boldDescription: "\u201CTapestry helps people think differently.",
    description:
      " It helps with dry topics to give people a jolt … You watch your ideas come to life.\u201D",
    designation: "Chief Marketing Officer",

    organisation: "Software company",
  },
  {
    heading: "Excitement",
    subHeading: "from users",
    boldDescription: "\u201CI could use it tomorrow.",
    description: " There is a big push for us to be more creative.\u201D",
    designation: "Senior Value Consultant",
    organisation: "HR solutions company",
  },
  {
    heading: "Excitement",
    subHeading: "from users",
    boldDescription: "\u201CTapestry is amazing\u201D",
    //description: " There is a big push for us to be more creative.",
    designation: "CEO",
    organisation: "Futures think tank",
  },
  {
    heading: "Excitement",
    subHeading: "from users",
    boldDescription: "\u201CTapestry is an anchoring heuristic.",
    description:
      " It brings the emotional aspects that are missing in online meetings.\u201D",
    designation: "Behavioral Health Medical Director",
    organisation: "Mid-states healthcare group",
  },
  {
    heading: "Excitement",
    subHeading: "from users",
    boldDescription: "\u201CInnovation is part of my job.",
    description:
      //" People struggle to get new ideas. A visual grounds the conversation. I have ongoing strategy meetings, a weekly check in. Afterwards, I do not want to read the whole transcript. I want to see the highlights.”",
      " Visual grounds the conversation and helps generate ideas.  After strategy sessions, I want the highlights.",
    designation: "Director of Product management",
    organisation: "Market-leading SaaS Company",
  },
]

var settings = {
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2700,
}

const Carousel = () => {
  return (
    <div className={style.textCarousel}>
      <Container>
        <div className={style.divWrapper}>
          <div className={style.placeholder}></div>
          <div className={style.carouselContainer}>
            <div className={style.heading}>
              <h1 className={style.carouselTitle}>Excitement</h1>
              <h2 className={style.carouselSubtitle}>
                from customers
             </h2>
            </div>

            <Slider {...settings}>
              <CarouselSlide carouselData={carouselData[0]} />
              <CarouselSlide carouselData={carouselData[1]} />
              <CarouselSlide carouselData={carouselData[2]} />
              <CarouselSlide carouselData={carouselData[3]} />
              <CarouselSlide carouselData={carouselData[4]} />
            </Slider>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Carousel
