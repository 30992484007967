import React from "react"

import Container from "../../reusable/absoluteContainer"

import * as styles from "./newFeature.module.css"

import hostMeeting from "../../../assets/images/host-meeting.png"
import meeting from "../../../assets/images/get-tapestry.png"
import share from "../../../assets/images/share-Tapestry.png"

const features = [
  {
    step: "1",
    heading: "Host ",
    subtitle: "your meeting",
    lineColor: "lineYellow",
    border: "yellow",
    img: hostMeeting,
  },
  {
    step: "2",
    heading: "Get your Tapestry",
    subtitle: "capturing the conversation",
    lineColor: "lineDark",
    border: "dark",
    img: meeting,
  },
  {
    step: "3",
    heading: "Spread the ideas",
    subtitle: "align insights into actions",
    lineColor: "lineLight",
    border: "light",
    img: share,
  },
]

const getColorClass = colorText => {
  const colorsData = [
    { color: "lineYellow", class: "yellow" },
    { color: "lineDark", class: "darkGrey" },
    { color: "lineLight", class: "lightGrey" },
    { color: "yellow", class: "yellowBorder" },
    { color: "dark", class: "darkBorder" },
    { color: "light", class: "lightBorder" },
  ]
  let className
  colorsData.map(item => {
    if (colorText === item.color) className = item.class
  })
  return className
}

const Features = () => {
  return (
    <div className={styles.featuresSection}>
      <Container center="true">
        <div className={styles.featuresParentDiv}>
          <div className={styles.featuresContents}>
            <div className={styles.wrapper}>
              <div className={styles.features}>
                {features.map((item, index) => (
                  <div key={index} className={styles.stepsContainer}>
                    <div className={styles.steps}>
                      <h4 className={styles.step}>{item.step}</h4>
                      <div
                        className={`${styles[getColorClass(item.lineColor)]}`}
                      ></div>
                      <div
                        className={`${
                          item.border === "yellow"
                            ? styles.yellowHeading
                            : styles.headingContainer
                        }`}
                      >
                        <div className={styles.desktopHeading}>
                          <h3 className={styles.heading}>{item.heading}</h3>
                          <h5 className={styles.subtitle}>{item.subtitle}</h5>
                        </div>
                        <p className={styles.mobileHeading}>
                          <span className={styles.heading}>
                            {item.heading}{" "}
                          </span>
                          {item.subtitle}
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        styles.imgContainer +
                        " " +
                        `${styles[getColorClass(item.border)]}`
                      }
                    >
                      <img src={item.img} alt={item.heading} />
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.paragraphContainer}>
                <div className={styles.paragraph}>
                  <h1 className={styles.title}>Transform</h1>
                  <h2 className={styles.subtitle2}>your meeting experience</h2>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Features
