import React from "react"

import Container from "../../reusable/absoluteContainer"
import Button from "../../reusable/button"

import * as styles from "./oneClick.module.css"

const oneClickList = [
  "One click to see your Tapestry",
  "Select phrases and images",
  "Capture your conversation's essence",
  "People align around visuals",
  "It's fun and enhances productivity",
]

const OneClick = () => {
  return (
    <div className={styles.oneClick}>
      <Container>
        <div className={styles.divWrapper}>
          <div className={styles.oneClickContents}>
            <div className={styles.leftContent}>
              <div className={styles.heading}>
                <h1 className={styles.oneClickTile}>Visual facilitation</h1>
                <h2 className={styles.oneClickSubtitle}>
                  energizes meetings
                </h2>
              </div>
              <p className={styles.mobileHeading}>
                <span className={styles.oneClickTile}>
                  Visual facilitation{" "}
                </span>
                energizes meetings
              </p>
              <ul className={styles.oneClickLists}>
                {oneClickList.map((item, index) => (
                  <li key={index} className={styles.list}>
                    {item}
                  </li>
                ))}
              </ul>
              <Button
                text="Start here"
                background=""
                link="http://app.tapestryai.com/signup"
              />
            </div>
            <div className={styles.emptyDiv}></div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default OneClick
