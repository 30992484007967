import React from "react"

import Container from "../../reusable/absoluteContainer"
import Button from "../../reusable/button"

import steps from "../../../assets/images/steps.png"

import * as styles from "./steps.module.css"

const Steps = () => {
  return (
    <div className={styles.steps}>
      <Container>
        <div className={styles.stepsContents}>
          <img src={steps} alt="steps" />
        </div>
        <Button
          text="Try it now"
          background=""
          link="http://app.tapestryai.com/signup"
        />
      </Container>
    </div>
  )
}

export default Steps
