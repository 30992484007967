import React from "react"

import Container from "../../reusable/absoluteContainer"

import * as styles from "./meetings.module.css"

const meetingsData = [
  "Images aid cognition and memory",
  "Art releases positive emotions",
  "Visual notes inspire reflection",
  "Navigate transcripts with ease",
//  "Post-meeting review adds value and saves time",
]

const Meetings = () => {
  return (
    <div className={styles.meetings}>
      <Container>
        <div className={styles.divWrapper}>
          <div className={styles.meetingsContents}>
            <div className={styles.leftContent}>
              <div className={styles.heading}>
                <h1 className={styles.meetingsTile}>Tapestries</h1>
                <h2 className={styles.meetingsSubtitle}>make ideas stick</h2>
              </div>
              <p className={styles.mobileHeading}>
                <span className={styles.meetingsTile}>Tapestries </span>
                make ideas stick
              </p>
              <ul className={styles.meetingsLists}>
                {meetingsData.map((item, index) => (
                  <li key={index} className={styles.meetingsList}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.emptyDiv}></div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Meetings
