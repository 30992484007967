import React from "react"

import Container from "../../reusable/absoluteContainer"
import Button from "../../reusable/button"

import * as styles from "./howItWork.module.css"

import computer from "../../../assets/images/tapestries.png"

const HowItWorks = () => {
  return (
    <div className={styles.howItWorks}>
      <Container>
        <div className={styles.howItWorksContents}>
          <div className={styles.howItWorksTitleComponent}>
            <h2 className={styles.howItWorksTitle}>How it works</h2>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <img
            src={computer}
            alt="tapestries"
            className={styles.howItWorksImg}
          />
          {/* <Button text="Watch the demo" background="gradient" /> */}
        </div>
      </Container>
    </div>
  )
}

export default HowItWorks
