import React from "react"

import Container from "../../reusable/absoluteContainer"
import Button from "../../reusable/button"

import star from "../../../assets/images/Star-2.png"
import eye from "../../../assets/images/Eye.png"
import computer from "../../../assets/images/meet.png"
import chatbot from "../../../assets/images/chatbot.png"

import * as styles from "./visualBlog.module.css"

const blogData = [
  { image: computer, title: "Why Art Humanizes Meetings", date: "11.2022" },
  { image: eye, title: "Visual Community", date: "01.2023" },
  {
    image: chatbot,
    title: "Prepare for the Chatbot Revolution",
    date: "02.2023",
  },
  {
    image: star,
    title: "This week’s Visual Goldstar Winner ",
    date: "03.2023",
  },
]
const VisualBlog = () => {
  return (
    <div className={styles.VisualBlog}>
      <Container>
        <div className={styles.VisualBlogContents}>
          <span className={styles.VisualBlogHeadings}>
            <h2 className={styles.blogThinTitle}>Our visual </h2>
            <h1 className={styles.blogBoldTitle}>blog</h1>
          </span>
          <div className={styles.blogsWrapper}>
            {blogData.map((item, index) => (
              <div className={styles.blogs} key={index}>
                <div className={styles.blogsImgWrapper}>
                  <img src={item.image} alt="" className={styles.blogImage} />
                </div>
                <div className={index === 2 && styles.blogsDateWrapper}>
                  <h6 className={styles.blogTitle}>{item.title}</h6>
                  <p className={styles.date}>{item.date}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <Button text="Read more" background="" /> */}
      </Container>
    </div>
  )
}

export default VisualBlog
