import React, { useState } from "react"

import Container from "../../reusable/absoluteContainer"
import AccordionContents from "../accordionContent"

import * as styles from "./accordion.module.css"

const accordionData = [
  {
    id: 1,
    title: " What kind of meetings benefit from Tapestry?",
    content:
      "Any meeting where ideas are exchanged: brainstorming, planning, decision-making, team-building, onboarding, personal sharing.",
  },
  {
    id: 2,
    title: "What is the business value?",
    content:
      "People stay engaged when a picture of their conversation shows up during the meeting with key quotes and evocative images–especially needed at a time when employee engagement is at a low point.",
  },
  {
    id: 3,
    title: "How does Tapestry help collaboration?",
    content:
      "Instead of focusing on individual voices, meeting participants see how their ideas fit together into a coherent picture that offers a fresh big-picture perspective.",
  },
  {
    id: 4,
    title: "How is Tapestry used?",
    content:
      "Tapestry pops up in an online meeting window with a visual story in words and images at a key point in the meeting.  A facilitator can briefly interpret the metaphors, creating a reflective moment for the group. Then the group discussion shifts to a deeper and more creative zone.",
  },
  {
    id: 5,
    title: "How does Tapestry help productivity?",
    content:
      "People see their work as part of the larger picture and this provides recognition, which contributes to motivation. Tapestry is a pleasant surprise during the meeting that contributes to work satisfaction. Participants have a copy of the Tapestry as a takeaway that will help them remember the meeting.",
  },

  {
    id: 6,
    title: "At what point in a meeting is a Tapestry used?",
    content:
      "At any point in a meeting when (1) energy is lagging, and/or (2) leaders want to refocus the conversation, and/or (3) it’s time to summarize key points, and/or (4) they are ready to move from brainstorming to solutions.",
  },
  {
    id: 7,
    title: "Who uses Tapestry?",
    content:
      "Any meeting where ideas are exchanged: brainstorming, planning, decision-making, team-building, onboarding, personal sharing.",
  },
  {
    id: 8,
    title: "Where can I read a science-based study?",
    content:
      'This article in <a target="_blank" rel="noopener noreferrer" href="https://www.artandobject.com/articles/neuroaesthetics-how-art-scientifically-proven-help-brain-health">Art and Object</a> provides an overview of new findings in the field of neuroaesthetics on how art helps brain health.',
  },
  // {
  //   id: 7,
  //   title: "How do you gauge Tapestry's effectiveness?",
  //   content:
  //     "Any meeting where ideas are exchanged: brainstorming, planning, decision-making, team-building, onboarding, personal sharing.",
  // },
]

const Accordion = () => {
  const [active, setActive] = useState(null)

  const handleToggle = index => {
    if (active === index) {
      setActive(null)
    } else {
      setActive(index)
    }
  }

  return (
    <div className={styles.accordion}>
      <Container>
        <div className={styles.divWrapper}>
          <div className={styles.accordionContents}>
            <div className={styles.accordionDetailWrapper}>
              <h1 className={styles.accordionTitle}>FAQs</h1>
              <div className={styles.accordionDetails}>
                {accordionData.map(item => (
                  <AccordionContents
                    data={item}
                    key={item.id}
                    active={active}
                    handleToggle={handleToggle}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Accordion
