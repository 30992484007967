import React from "react"

import Layout from "../components/layout"
import HeroSection from "../components/newHome/hero"
import OneClick from "../components/newHome/oneClick"
import Inspire from "../components/newHome/inspire"
import Meetings from "../components/newHome/meetings"
import NewFeature from "../components/newHome/newFeatures"
import HowItWorks from "../components/newHome/howItWork"
import Steps from "../components/newHome/steps"
import VisualBlog from "../components/newHome/visualBlog"
import Carousel from "../components/newHome/carousel"
import Accordion from "../components/newHome/accordion"
import ThanYou from "../components/newHome/thankYou"

import "../styles/global.css"

const Home = () => {
  return (
    <Layout>
      <HeroSection />
      <OneClick />
      <Inspire />
      <Meetings />
      <NewFeature />
      <HowItWorks />
      <Steps />
      <Carousel />
      <Accordion />
      <VisualBlog />
      <ThanYou />
    </Layout>
  )
}

export default Home
