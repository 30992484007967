import React from "react"

import Container from "../../reusable/absoluteContainer"
import Button from "../../reusable/button"

import * as styles from "./inspire.module.css"

const inspireData = [
  "Visuals promote shared vision",
  "Metaphors support sense-making",
  "Ideas become tangible",
  "Words capture ideas; images free them",
  //"Art releases positive emotions",
]

const Inspire = () => {
  return (
    <div className={styles.inspire}>
      <Container>
        <div className={styles.divWrapper}>
          <div className={styles.inspireContents}>
            <div className={styles.leftContent}></div>
            <div className={styles.rightContent}>
              <div className={styles.heading}>
                <h1 className={styles.inspireTile}>Inspire</h1>
                <h2 className={styles.inspireSubtitle}>
                  creativity and engagement
                </h2>
              </div>
              <p className={styles.mobileHeading}>
                <span className={styles.inspireTile}>Inspire </span> creativity and engagement
              </p>
              <ul className={styles.inspireLists}>
                {inspireData.map((item, index) => (
                  <li key={index} className={styles.inspireList}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Button
            text="Start here"
            background=""
            link="http://app.tapestryai.com/signup"
          />
        </div>
      </Container>
    </div>
  )
}

export default Inspire
