import React from "react"

import * as styles from "./accordionContent.module.css"

const AccordionContents = ({ data, handleToggle, active }) => {
  const { title, id, content } = data

  return (
    <div className={styles.accordionData}>
      <div
        className={styles.accordionHeadings}
        onClick={() => handleToggle(id)}
      >
        <div className={styles.icons}>
          {active === id ? <div className={styles.minus}> - </div> : "+"}
        </div>
        <h5 className={styles.accordionSubTitle}>{title}</h5>
      </div>
      {active === id && (
        <h5 className={styles.accordionPara}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </h5>
      )}
    </div>
  )
}

export default AccordionContents
