import React from "react"

import * as style from "./carouselSlide.module.css"

const CarouselSlide = ({ carouselData }) => {
  return (
    <div className={style.carouselWrapper}>
      {/* <div className={style.desktopHeading}>
        <h1>{carouselData.heading}</h1>
        <h2>{carouselData.subHeading}</h2>
      </div>
      <div className={style.mobileHeading}>
        <p>
          <span>{carouselData.heading} </span> {carouselData.subHeading}
        </p>
      </div> */}
      <div className={style.carouselContents}>
        <h5 className={style.quotes}>
          <span>{carouselData.boldDescription}</span>
          {carouselData.description}
        </h5>
        <div className={style.designation}>
          <p className={style.smallText}>{carouselData.designation}</p>
          <p className={style.smallText}>{carouselData.organisation}</p>
        </div>
      </div>
    </div>
  )
}

export default CarouselSlide
